import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import HomeIcon from '@mui/icons-material/Home';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import FlightIcon from '@mui/icons-material/Flight';
import CallIcon from '@mui/icons-material/Call';
import LockIcon from '@mui/icons-material/Lock';
import "./navbar.css";
import { useNavigate } from "react-router-dom";

function NavbarComponent() {
  const navigate = useNavigate();

  const handleDestinationsClick = (destination = null) => {
    // Navigate to home page with hash for destinations
    navigate('/#destinations');
  };

  return (
    <Navbar expand="lg" className="bg-white shadow-sm fixed-top">
      <Container>
        <Navbar.Brand href="/" className="brand">EMATRIX</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto nav-links">
            <Nav.Link href="/" className="custom-nav-link"><HomeIcon className="nav-icon" fontSize="small" />〡Home</Nav.Link>
            <Nav.Link href="/about-us" className="custom-nav-link"><BusinessCenterIcon className="nav-icon" />〡About Us</Nav.Link>
            <NavDropdown title={<span className="custom-nav-link"><FlightIcon className="nav-icon" />〡Top Destinations</span>}>
              <NavDropdown.Item onClick={() => handleDestinationsClick('toronto')}>Flights to Toronto</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleDestinationsClick('las-vegas')}>Flights to Las Vegas</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleDestinationsClick('london')}>Flights to London</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleDestinationsClick('sydney')}>Flights to Sydney</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/contact-us" className="custom-nav-link"><CallIcon className="nav-icon" />〡Contact us</Nav.Link>
            <Nav.Link href="/privacy-policy" className="custom-nav-link"><LockIcon className="nav-icon" />〡Privacy Policy</Nav.Link>
          </Nav>
          <div className="contact-info">
            <div className="call-text">Call Us 24x7</div>
            <Nav.Link href="tel:++18881323047" className="phone-link">
              <CallIcon className="call-icon" />
              <span className="phone-number">(888) 132-3047</span>
            </Nav.Link>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;