import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import istanbulImage from '../Images/istanbul.jpg';
import torontoImage from '../Images/toronto.jpg';
import sydneyImage from '../Images/sydney.jpg';
import lasVegasImage from '../Images/las-vegas.jpg';
import bangkokImage from '../Images/bangkok.jpg';
import londonImage from '../Images/london.jpg';
import '../index.css'

const destinations = [
  { city: 'Istanbul', country: 'Turkey', price: 289, image: istanbulImage },
  { city: 'Toronto', country: 'Canada', price: 330, image: torontoImage },
  { city: 'Sydney', country: 'Australia', price: 599, image: sydneyImage },
  { city: 'Las Vegas', country: 'USA', price: 312, image: lasVegasImage },
  { city: 'Bangkok', country: 'Thailand', price: 297, image: bangkokImage },
  { city: 'London', country: 'UK', price: 325, image: londonImage },
];

const TravelDestinationCards = () => {
  return (
    <Grid id="destinations-section" container spacing={2} sx={
        {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px 40px', 
        }
    }>
      {destinations.map((destination, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <a href="tel:++18881323047" style={{ textDecoration: 'none' }}>
          <Card sx={{ position: 'relative', overflow: 'hidden', fontFamily: 'Roboto, sans-serif' }}>
            <div style={{ overflow: 'hidden', height: '300px' }}>
              <CardMedia
                component="img"
                height="300"
                image={destination.image}
                alt={`${destination.city}, ${destination.country}`}
                sx={{
                  transition: 'all 0.4s ease-in-out',
                  '&:hover': { transform: 'scale(1.1)' },
                }}
              />
            </div>
            <CardContent style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" component="div" gutterBottom sx={{ fontFamily: 'Roboto, sans-serif', fontSize:'18px', color:'#00509d' }}>
                {destination.city.toUpperCase()}
              </Typography>
              <Typography variant="h5" color="primary" sx={{ fontFamily: 'Roboto, sans-serif',fontSize:'20px', color:'#ff0000' }}>
                ${destination.price}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                  bgcolor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'Roboto, sans-serif'
                }}
              >
                <LocationOn fontSize="small" sx={{ marginRight: 0.5, color: 'red' }} />
                {destination.country}
              </Typography>
            </CardContent>
          </Card>
          </a>
        </Grid>
      ))}
    </Grid>
  );
};

export default TravelDestinationCards;
