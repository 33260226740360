import React from 'react';
import { Box, Typography, Paper, Grid, Container } from '@mui/material';
import { styled } from '@mui/system';
import abtusImg from '../Images/header.jpg'
import aboutImg from '../Images/about.jpg'
import Footer from './footer';
import PaymentMethods from './footer-2';
const HeaderImage = styled(Box)({
    width: '100%',
    height: '300px',
    backgroundImage: `url(${abtusImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
});

const ContentPaper = styled(Paper)(({ theme }) => ({
    padding: `${theme.spacing(3)}`,
    marginTop: '-50px',
    position: 'relative',
    backgroundColor: 'white',
}));
const HeaderText = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff', // Adjust color for visibility
    textAlign: 'center',
    padding: '10px',
    fontSize: '2.5rem', // Default font size
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem', // Smaller font size for small screens
      padding: '8px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem', // Even smaller font size for extra small screens
      padding: '5px',
    },
  }));

const AboutUs = () => {
    return (
        <>
            <Box>
                <HeaderImage>
                <HeaderText variant="h4" component="h1">
                About ReservationsVacations
          </HeaderText>
                </HeaderImage>
                <Container maxWidth="false" sx={{maxWidth:'1200px'}}>
                    <ContentPaper elevation={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8}>
                                <Typography variant="h4" gutterBottom fontWeight="bold">
                                    About ematrix
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    At ematrix, our goal is to give our customers the best travel arrangements in remote destinations, together with luxurious, first-rate amenities that round out the ideal vacation experience. When customers purchase tickets at ematrix, they can travel in peace of mind knowing that we will provide them with the lowest cost range of airline booking options as well as the best rental car prices. Through our website, customers can readily access services and receive instant travel confirmations that can be tailored to their specific requirements for a hassle-free travel experience.
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    ematrix offers highly competitive rates that are not available to individual travelers and are not available by phone. Our travel specialists at ematrix are available 24/7 to provide you with the widest selection of travel packages and ensure that you have an impeccable experience. Customers can customize their travel plans and benefit from the best offers and amazing discounts provided by airlines.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box
                                    component="img"
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                        maxHeight: 250,
                                        objectFit: 'cover',
                                        borderRadius: '4px',
                                        marginLeft: {
                                            xs: '0',     // No margin for mobile
                                            md: '50px',  // Small margin for desktop
                                        },
                                        marginRight: {
                                            xs: '0',     // No margin for mobile
                                            md: '16px',  // Small margin for desktop
                                        },
                                    }}
                                    alt="Travel destination"
                                    src={aboutImg}
                                />
                            </Grid>
                        </Grid>
                    </ContentPaper>
                </Container>
            </Box>
            <Footer />
            <PaymentMethods />
        </>
    );
};

export default AboutUs;