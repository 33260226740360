import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const scrollToDestinations = () => {
  const destinationsSection = document.getElementById('destinations-section');
  if (destinationsSection) {
    window.history.replaceState(null, '', '/#destinations');
    destinationsSection.scrollIntoView({ behavior: 'smooth' });
  }
};

// Custom hook to handle scrolling after navigation
export const useScrollToDestinations = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/' && location.hash === '#destinations') {
      const timer = setTimeout(() => {
        scrollToDestinations();
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [location]);
};