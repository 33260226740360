import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./components/about-us";
import Contact from "./components/contact-us";
import NavbarComponent from "./components/navbar";
import './index.css'
import Home from "./components";
import InfoComponent from "./components/privacy-policy";
import TermsConditions from "./components/terms-conditions";
const App = () =>{
    return(
        <BrowserRouter>
        <NavbarComponent/>
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="about-us" element={<About/>}/>
            <Route exact path="contact-us" element={<Contact/>}/>
            <Route exact path="privacy-policy" element={<InfoComponent/>}/>
            <Route exact path="terms-conditions" element={<TermsConditions/>}/>
        </Routes>
        </BrowserRouter>
    )
}
export default App