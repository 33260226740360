import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Popover,
  Typography,
  IconButton,
  Autocomplete,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PersonIcon from '@mui/icons-material/Person';
import FlightIcon from '@mui/icons-material/Flight';
import headerImage from '../Images/headerImg.webp';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Popper from '@mui/material/Popper';
import '../index.css';
import airports from '../airport.json';
import TravelDestinationCards from './cards';
import Footer from './footer';
import PaymentMethods from './footer-2';
import { useScrollToDestinations } from '../utils/scrollToElement';

const CustomPopper = (props) => {
  return <Popper {...props} placement="bottom-start" />;
};
const PassengersSelector = ({ anchorEl, handleClose, passengers, setPassengers }) => {
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);

  const totalPassengers = adults + children + infants;

  const handleAdultChange = (operation) => {
    if (operation === 'increment' && totalPassengers < 9) {
      setAdults((prev) => prev + 1);
    } else if (operation === 'decrement' && adults > 1) {
      setAdults((prev) => prev - 1);
    }
  };

  const handleChildChange = (operation) => {
    if (operation === 'increment' && totalPassengers < 9) {
      setChildren((prev) => prev + 1);
    } else if (operation === 'decrement' && children > 0) {
      setChildren((prev) => prev - 1);
    }
  };

  const handleInfantChange = (operation) => {
    if (operation === 'increment' && totalPassengers < 9) {
      setInfants((prev) => prev + 1);
    } else if (operation === 'decrement' && infants > 0) {
      setInfants((prev) => prev - 1);
    }
  };

  const handleDone = () => {
    setPassengers(totalPassengers);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box p={2} sx={{ width: '250px' }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <Typography id='psgs'>Adults (+11 yrs)</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} display={'flex'}>
          <IconButton onClick={() => handleAdultChange('decrement')}><RemoveIcon id="rmvicn" /></IconButton>
          <Typography id="adlts">{adults}</Typography>
          <IconButton onClick={() => handleAdultChange('increment')}><AddIcon id="adicn" /></IconButton>
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <Typography id='psgs'>Children (2-11 yrs)</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} display="flex">
          <IconButton onClick={() => handleChildChange('decrement')}><RemoveIcon id="rmvicn" /></IconButton>
          <Typography id="adlts">{children}</Typography>
          <IconButton onClick={() => handleChildChange('increment')}><AddIcon id="adicn" /></IconButton>
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <Typography id='psgs'>Infants (upto 2 yrs)</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <RadioGroup row aria-label="infant seating" name="infantSeating" defaultValue="onLap">
              <FormControlLabel value="onLap" control={<Radio />} label={<Typography sx={{ fontSize: '12px' }}>On Lap</Typography>} />
              <FormControlLabel value="onSeat" control={<Radio />} label={<Typography sx={{ fontSize: '12px' }}>On Lap</Typography>} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6} display="flex">
          <IconButton onClick={() => handleInfantChange('decrement')}><RemoveIcon id="rmvicn" /></IconButton>
          <Typography id="adlts">{infants}</Typography>
          <IconButton onClick={() => handleInfantChange('increment')}><AddIcon id="adicn" /></IconButton>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleDone}
          sx={{ mt: 2 }}
        >
          Done
        </Button>
      </Box>
    </Popover>
  );
};

const parseAirport = (airportString) => {
  const [code, ...rest] = airportString.split('-');
  const name = rest.join('-').trim();
  return { code, name };
};
const Home = () => {
    useScrollToDestinations();
  const [tripType, setTripType] = useState('roundTrip');
  const [fromCity, setFromCity] = useState(null);
  const [toCity, setToCity] = useState(null);
  const [departDate, setDepartDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [passengers, setPassengers] = useState(1);
  const [travelClass, setTravelClass] = useState('economy');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleTripTypeChange = (event, newTripType) => {
    if (newTripType !== null) {
      setTripType(newTripType);
    }
  };

  const handlePassengersClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePassengersClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({
      tripType,
      fromCity: fromCity ? parseAirport(fromCity).name : '',
      toCity: toCity ? parseAirport(toCity).name : '',
      departDate,
      returnDate,
      passengers,
      travelClass
    });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <header style={{ position: 'relative' }} id='srch-hdr'>
          <img src={headerImage} alt="Header" className="header-img" />
          <Typography
            variant="h4"
            component="div"
            sx={{
              position: 'absolute',
              top: {
                xs: '15%', // Extra small devices (phones)
                sm: '20%', // Small devices (tablets)
                md: '22%', // Medium devices (small laptops/tablets)
                lg: '25%', // Large devices (desktops)
                xl: '25%',
              },
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
              width: '80%', // Set a width for the typography to ensure it wraps
              textAlign: 'center', // Center the text
              whiteSpace: 'normal', // Allow text to wrap onto the next line
              overflow: 'hidden', // Hide overflow if needed
              fontSize: {
                xs: '25px', // Extra small devices
                sm: '24px', // Small devices
                md: '28px', // Medium devices
                lg: '36px', // Large devices
                xl: '40px', // Extra large devices
              },
            }}
            id='heading'
          >
            Book Your Flight Now and Lock in the Best Price Guaranteed!
          </Typography>
          <Typography
            variant="h4"
            component="div"
            sx={{
              position: 'absolute',
              top: '35%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
              whiteSpace: 'nowrap',
              fontSize: '40px'
            }}
            id='heading2'
          >
            Discover Budget-Friendly Options!
          </Typography>

          <Container maxWidth="xl" className="form-container"
            sx={{
              top: {
                xs: '40%',
                sm: '25%',
                md: '25%',
                lg: '40%',
                xl: '40%'
              },
            }}>
            <Grid backgroundColor={'white'}
              color={'#000'}
              width={'100px'}
              display={'flex'}
              justifyContent={'center'}
              padding={'4px 8px'}
              boxShadow={'10px'}
              borderRadius={'6px 6px 0px 0px'}
            >
              <FlightIcon fontSize='small' sx={{
                transform: 'rotate(90deg)',
                mt: '2px',
                mr: '4px',
                color: '#00509d'
              }} />
              Flights
            </Grid>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                backgroundColor: 'white',
                borderRadius: '0px 6px 6px',
                padding: 2,
                boxShadow: 3,
              }}
            >
              <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                <Grid item xs={12}>
                  <FormControl component="fieldset" sx={{ width: '100%' }}>
                    <RadioGroup
                      aria-label="trip type"
                      name="tripType"
                      value={tripType}
                      onChange={handleTripTypeChange}
                      row
                    >
                      <FormControlLabel
                        value="roundTrip"
                        control={<Radio sx={{ color: '#00509d', '&.Mui-checked': { color: '#00509d' } }} />}
                        label="Round Trip"
                      />
                      <FormControlLabel
                        value="oneWay"
                        control={<Radio sx={{ color: '#00509d', '&.Mui-checked': { color: '#00509d' } }} />}
                        label="One Way"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    fullWidth
                    options={airports}
                    PopperComponent={CustomPopper}
                    getOptionLabel={(option) => {
                      const { code, name } = parseAirport(option);
                      return `${name} (${code})`;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="From Airport / City"
                        placeholder="City or Airport"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <FlightTakeoffIcon sx={{ mr: 1, transform: 'rotate(-45deg)', color: '#00509d' }} />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    value={fromCity}
                    onChange={(event, newValue) => setFromCity(newValue)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    fullWidth
                    options={airports}
                    PopperComponent={CustomPopper}
                    getOptionLabel={(option) => {
                      const { code, name } = parseAirport(option);
                      return `${name} (${code})`;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="To Airport / City"
                        placeholder="City or Airport"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <FlightTakeoffIcon sx={{ mr: 1, transform: 'rotate(45deg)', color: '#00509d' }} />
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    value={toCity}
                    onChange={(event, newValue) => setToCity(newValue)}
                  />
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                  <DatePicker
                    label="Departure"
                    value={departDate}
                    onChange={(newValue) => setDepartDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                  <DatePicker
                    label="Return"
                    value={returnDate}
                    onChange={(newValue) => setReturnDate(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    disabled={tripType === 'oneWay'}
                  />
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                  <TextField
                    fullWidth
                    label="Passengers"
                    value={`${passengers} ${passengers > 1 ? 'Passengers' : 'Passenger'}`}
                    onClick={handlePassengersClick}
                    InputProps={{
                      startAdornment: <PersonIcon sx={{ mr: 1, color: '#00509d' }} />,
                      readOnly: true,
                    }}
                  />
                  <PassengersSelector
                    anchorEl={anchorEl}
                    handleClose={handlePassengersClose}
                    passengers={passengers}
                    setPassengers={setPassengers}
                  />
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                  <FormControl fullWidth>
                    <InputLabel>Class</InputLabel>
                    <Select
                      value={travelClass}
                      label="Class"
                      onChange={(e) => setTravelClass(e.target.value)}
                    >
                      <MenuItem value="economy">Economy</MenuItem>
                      <MenuItem value="business">Business</MenuItem>
                      <MenuItem value="first">First</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: '#0066cc',
                      '&:hover': {
                        backgroundColor: '#00509d',
                      },
                    }}
                    onClick={(e) => {
                        e.preventDefault(); // Prevent the form submission
                        if (
                            fromCity &&
                            toCity &&
                            departDate &&
                            passengers > 0 &&
                            travelClass
                          ) {
                            alert("Thank you for reaching out! Our team will contact you shortly.");
                            window.location.reload(); // Reload the page
                          } else {
                            alert("Please fill in all the fields before searching.");
                          }
                        }}
                      >
                        Search
                      </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </header>
      </LocalizationProvider>
      <div className='heading' style={
        {
          marginTop: '50px'
        }
      } id='flight-offer'>
        <h2 style={
          {
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#00509d'
          }}>flight offeres</h2>
        <h3 style={{ textAlign: 'center', color: '#ff0000' }}>───────── <FlightIcon sx={{ transform: 'rotate(90deg)' }} /> ────────</h3>
      </div>
      <TravelDestinationCards />
      <Footer />
      <PaymentMethods />
    </>
  );
};

export default Home;