import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { keyframes } from '@mui/system';
import { styled } from '@mui/system';
import visaImg from '../Images/visa.png'
import mCardImg from '../Images/mastercard.png'
import discoverImg from '../Images/discover.png'
import axpressImg from '../Images/axpress.png'
import dinersClubImg from '../Images/diners-club.png'
import paypalImg from '../Images/paypal.png'
import trustpilotImg from '../Images/trustpilot.png'
import verfiedImg from '../Images/verified.png'
import '../index.css'
const DisclaimerText = styled(Typography)({
  fontSize: '14px',
  color: 'black',
  marginBottom: '16px',
  textAlign: 'center',
});

const slide = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const PaymentMethodsContainer = styled(Box)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'relative',
  padding: '60px 0',
  '&::before, &::after': {
    position: 'absolute',
    top: 0,
    width: '80px',
    height: '100%',
    content: '""',
    zIndex: 2,
  },
  '&::before': {
    left: 0,
    background: 'linear-gradient(to left, rgba(255, 255, 255, 0), #fff)',
  },
  '&::after': {
    right: 0,
    background: 'linear-gradient(to right, rgba(255, 255, 255, 0), #fff)',
  },
});

const PaymentLogosTrack = styled(Box)({
  display: 'inline-block',
  animation: `${slide} 40s infinite linear`,
  '&:hover': {
    animationPlayState: 'paused',
  },
});

const PaymentLogo = styled('img')({
  height: '50px',
  objectFit: 'contain',
  margin: '0 40px',
});
const CopyrightContainer = styled('div')({
  width: '100%',
  textAlign: 'center',
  backgroundColor: '#00509d',
  color: 'white',
  padding: '15px 0',
  fontSize: '14px',
  borderTop: '1px solid #ccc',
  fontFamily:'Outfit, sans-serif'
});
const PaymentMethods = () => {
  const paymentLogos = [
    { name: 'Visa', src: visaImg },
    { name: 'MasterCard', src: mCardImg },
    { name: 'Discover', src: discoverImg },
    { name: 'American Express', src: axpressImg },
    { name: 'Diners Club', src: dinersClubImg },
    { name: 'PayPal', src: paypalImg },
    { name: 'Verified & Secured', src: trustpilotImg },
    { name: 'Trustpilot', src: verfiedImg }
  ];

  return (
    <>
      <Container maxWidth={false}>
        <DisclaimerText>
        Disclaimer: Under no circumstances shall ematrix.us, along with its parent companies, affiliates, subsidiaries, associates, officers, directors, employees, representatives, or suppliers, be liable for any direct, indirect, punitive, consequential, special, contingent, or other damages. This includes, but is not limited to, damages for loss of use, data, or profits arising from or related to this agreement in any way.
        </DisclaimerText>
        <PaymentMethodsContainer id='cards-section'>
          <PaymentLogosTrack>
            {paymentLogos.map((logo, index) => (
              <PaymentLogo key={`${logo.name}-${index}`} src={logo.src} alt={logo.name} />
            ))}
            {paymentLogos.map((logo, index) => (
              <PaymentLogo key={`${logo.name}-repeat-${index}`} src={logo.src} alt={logo.name} />
            ))}
          </PaymentLogosTrack>
        </PaymentMethodsContainer>
      </Container>
      <CopyrightContainer>
        <Typography variant="body2">
          Copyright © 2024 ematrix.us All rights reserved.
        </Typography>
      </CopyrightContainer>
    </>
  );
};

export default PaymentMethods;