import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent } from '@mui/material';
import { LocationOn, Phone, Email } from '@mui/icons-material';
import { styled } from '@mui/system';
import contactImg from '../Images/contact-us.jpg'
import Footer from './footer';
import PaymentMethods from './footer-2';

const HeaderImage = styled(Box)({
  width: '100%',
  height: '300px',
  backgroundImage: `url(${contactImg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
});

const ContactCard = styled(Card)({
  marginTop: '-100px',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  padding: '32px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
});

const ContactInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '16px',
});

const ContactUs = () => {
  return (
    <>
    <Box>
      <HeaderImage>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            textAlign: 'center',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Need Help Contact Us
          </Typography>
        </Box>
      </HeaderImage>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <ContactCard elevation={3}>
          <Typography variant='h6'>
          ReservationsVacations Contact Us !
          </Typography>
          <CardContent>
            <Grid container spacing={4}>
              {/* Address */}
              <Grid item xs={12}>
                <ContactInfo>
                  <LocationOn sx={{ fontSize: 32, marginRight: 2, color:'#ff0000' }} />
                  <Box>
                    <Typography variant="h5" gutterBottom>
                      Address
                    </Typography>
                    <Typography variant="body1">
                      285 Fulton St, New York, NY 10007 USA
                    </Typography>
                  </Box>
                </ContactInfo>
              </Grid>
              {/* Phone */}
              <Grid item xs={12}>
                <ContactInfo>
                  <Phone sx={{ fontSize: 32, marginRight: 2, color:'#ff0000' }} />
                  <Box>
                    <a href='tel:++18881323047' style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography variant="h5" gutterBottom>
                        Toll Free
                      </Typography>
                      <Typography variant="body1">
                        (888) 508-6798
                      </Typography>
                    </a>
                  </Box>
                </ContactInfo>
              </Grid>
              {/* Email */}
              <Grid item xs={12}>
                <ContactInfo>
                  <Email sx={{ fontSize: 32, marginRight: 2, color:'#ff0000' }} />
                  <Box>
                    <a href='mailto:' style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography variant="h5" gutterBottom>
                        Email Address
                      </Typography>
                      <Typography variant="body1">
                        support@ematrix.us
                      </Typography>
                    </a>
                  </Box>
                </ContactInfo>
              </Grid>
            </Grid>
          </CardContent>
          {/* Uncomment if you want a button */}
          {/* <CardActions sx={{ justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" color="primary" size="large">
              Contact Us
            </Button>
          </CardActions> */}
        </ContactCard>
      </Container>
    </Box>
    <Footer/>
    <PaymentMethods/>
    </>
  );
};

export default ContactUs;
