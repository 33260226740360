import React from 'react';
import { Box, Grid, Typography, TextField, Button, Link } from '@mui/material';
import { styled } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import '../index.css'
import { useNavigate } from 'react-router-dom';
import { scrollToDestinations } from '../utils/scrollToElement';

const FooterWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: '#00509d',
    color: '#ffffff',
    padding: '32px 0',
    fontFamily: 'Outfit, sans-serif'
}));

const FooterContent = styled(Box)(({ theme }) => ({
    margin: '0 auto',
    padding: '0px 48px',

}));

const SectionTitle = styled(Typography)({
    fontWeight: 'bold',
    marginBottom: '16px',
    '&::after': {
        content: '""',
        display: 'block',
        width: '40px',
        height: '2px',
        backgroundColor: '#ffffff',
        marginTop: '8px',
    },
});

const StyledLink = styled(Link)({
    color: '#ffffff',
    textDecoration: 'none',
});

const ContactItem = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
});
const handleClick = () => {
    window.location.reload(); // Refresh the page
};

const Footer = () => {
    const navigate = useNavigate();

  const handleDestinationsClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    
    if (window.location.pathname !== '/') {
        navigate('/');
      }
      setTimeout(() => {
        scrollToDestinations();
      }, 100);
  };
    return (
        <FooterWrapper>
            <FooterContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <SectionTitle variant="h6">Subscribe Exploore To Get Latest Deals To Day</SectionTitle>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            ReservationsVacations seamlessly connects millions of travelers to memorable experiences
                        </Typography>
                        <Box component="form" noValidate sx={{ display: 'flex' }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Write your email"
                                sx={{
                                    flex: 1,
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: '#ffffff',
                                    }
                                }}
                            />
                            <Button
                                variant="contained"
                                sx={{
                                    ml: 1, backgroundColor: '#ff0000', '&:hover': {
                                        backgroundColor: '#cc0000',
                                    },
                                }}
                                onClick={handleClick}
                            >
                                <SendIcon/>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SectionTitle variant="h6">Quick Links</SectionTitle>
                        <Grid container spacing={2}>
                            {[
                                { name: 'Home', href: '/' },
                                { name: 'About us', href: '/about-us' },
                                { name: 'Contact Us', href: '/contact-us' },
                                { name: 'Top Destinations', href: '/#destinations', onclick: handleDestinationsClick },
                                { name: 'Privacy Policy', href: '/privacy-policy' },
                                { name: 'Terms & Conditions', href: '/terms-conditions'}
                            ].map((item) => (
                                <Grid item xs={6} key={item.name}>
                                    <StyledLink href={item.href} variant="body2">{item.name}</StyledLink>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <SectionTitle variant="h6">Top Destinations</SectionTitle>
                        <Grid container spacing={2}>
                            {[
                                {name: 'Toronto Flights', href: '/#destinations', onclick: handleDestinationsClick},
                                {name: 'London Flights', href: '/#destinations', onclick: handleDestinationsClick},
                                {name: 'Sydney Flights', href: '/#destinations', onclick: handleDestinationsClick},
                                {name: 'Istanbul Flights', href: '/#destinations', onclick: handleDestinationsClick},
                                {name: ''}
                               ].map((item) => (
                                <Grid item xs={6} key={item.name}>
                                    <StyledLink href={item.href} variant="body2">{item.name}</StyledLink>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 4, pt: 2, borderTop: '1px solid rgba(255,255,255,0.1)' }}>
                    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} md={4}>
                            <ContactItem>
                                <PhoneIcon sx={{ mr: 1 }} />
                                <a href="tel:++18881323047" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Typography variant="body2">Call Now: (888) 132-3047</Typography>
                                </a>
                            </ContactItem>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <ContactItem>
                                <EmailIcon sx={{ mr: 1 }} />
                                <a href="mailto:" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Typography variant="body2">support@ematrix.us</Typography>
                                </a>
                            </ContactItem>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <ContactItem>
                                <LocationOnIcon sx={{ mr: 1 }} />
                                <Typography variant="body2">285 Fulton St, New York, NY 10007 USA</Typography>
                            </ContactItem>
                        </Grid>
                    </Grid>
                </Box>
            </FooterContent>
        </FooterWrapper>
    );
};

export default Footer;